<div class="modal-header">
  <button type="button" class="close" data-dismiss="modal"
    aria-label="Click to close this modal" (click)="dismiss()">
    <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
  </button>
  <h4 id="assign-users-label" class="modal-title">Assign Users</h4>
</div>
<div class="modal-body text-left">
  <div id="errorBox" [shown]="users.apiError" class="alert alert-danger text-danger" role="alert">
    <strong>{{users.errorMessage}}</strong> {{users.apiError}}
  </div>
  <!-- Search -->
  <search-filter [filterConfig]="filterConfig" [search]="search" [doSearch]="users.doSearch" iconSet="madero"></search-filter>
  <!-- List of Users -->
  <div class="panel panel-default scrollable-list border-container u_margin-sm-top mb-2"
    scrollingList (scrollEndEvent)="users.load()"
    rvSpinner="company-users-list" [rvShowSpinner]="users.loadingItems">
    <table class="table">
      <thead class="table-header">
        <tr class="table-header__row">
          <th class="table-header__cell u_clickable">
            Username
          </th>
        </tr>
      </thead>
      <tbody class="table-body">
        @for (user of users.items.list; track user) {
          <tr class="table-body__row">
            <td class="table-body__cell flex-row u_clickable" [ngClass]="{ 'selected': userSelected(user.id) }" (click)="toggleUser(user.id)">
              <mat-checkbox [ngModel]="userSelected(user.id)"></mat-checkbox>
              <p class="list-group-item-text"><strong>{{user.firstName}} {{user.lastName}}</strong><small class="text-muted ml-2">{{user.email}}</small></p>
            </td>
          </tr>
        }
        @if (!users.loadingItems && users.items.list.length === 0) {
          <tr class="table-body__row">
            <td class="table-body__cell">
              <p class="list-group-item-text">No assignable users</p>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default btn-toolbar" data-dismiss="modal" (click)="dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-success btn-toolbar" (click)="apply()">
    Assign Users
  </button>
</div>
